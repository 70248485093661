@import '../../styles/mediaqueries';

.modal_container{

    .overlay{
        position: fixed;
        top:0;
        left:0;
        height: 100%;
        width:100%;
        //background:red;
        z-index: 9;
        display: none;

        &.visible{
            display: flex;
        }
    }
    .modal{
        position: fixed;
        max-width: 600px;
        padding: 32px;
        width: calc(100% - 40px);
        //max-height: calc(100% - 40px);
        max-height: max-content;
        z-index: 10;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin: auto;
        background-color: #202020;
        border: 1px solid #F36C17;
        border-radius: 12px;
        box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        z-index: -1;
        opacity: 0;
        transform:translateY(100px);
        transition: all 200ms ease-in;

        &.visible{
            z-index:10;
            opacity: 1;
            transform:translateY(0px);
        }

        .close{
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
            transform: rotate(45deg);
            font-size: 20px;

        }
        .modal_pretitle{
            color: #fff;
            opacity: 0.8;
            font-size: 14px;
            text-align: center;
            @include media(md){
                font-size: 16px;
            }
        }
        .modal_title{
            color: #fff;
            font-size: large;
            text-align: center;
            font-size: 22px;
            @include media(md){
                font-size: 20px;
            }
        }

        .msg{
            color: #fff;
            font-size: large;
            text-align: center;
            font-size: 14px;

            .submsg{
                font-size: 20px;
                text-align: left;
                @include media(md){
                    font-size: 14px;
                    max-width: 100%;
                    margin: auto;
                    max-height: 370px;
                    padding:0 5px;
                    overflow-y: scroll;
                }
            }
        }
        .cta{
            color: white;
            border: 0;
            font-size: 22px;
            font-weight: normal;
            cursor: pointer;
            transition: all 0.2s ease;
            display: block;
            display: block;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 300px;
            
            background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
            border-radius: 30px;
            padding: 8px;
            cursor: pointer;
            text-transform: uppercase;

            @keyframes animatedgradient {
                0%{background-position:0% 50%}
                50%{background-position:100% 50%}
                100%{background-position:0% 50%}
            }
            &:hover{
                transform: scale(1.03);
                background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #ec2c01,#E23B17, #F36C17, #f7b722, #f7f022 );
                animation: animatedgradient 3s ease alternate infinite;
                background-size: 300% 300%;
            }
            &.danger{
                background-color: #ff4444;
                &:hover{
                    transform: scale(0.98);
                    box-shadow: unset;
                    -webkit-box-shadow: unset;
                    -moz-box-shadow: unset;
                    opacity:0.75;
                }
            }
            &.small{
                font-size:16px;
            }
            &.disabled{
                opacity: 0.5;
                cursor: not-allowed;
                //pointer-events: none;
                &:hover{
                    transform: unset;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                }
                &:active{
                    animation-iteration-count: 1;
                    animation: horizontal-shaking 200ms linear;
                }
            }
            @keyframes horizontal-shaking {
                0% { transform: translateX(0) }
                25% { transform: translateX(5px) }
                50% { transform: translateX(-5px) }
                75% { transform: translateX(5px) }
                100% { transform: translateX(0) }
            }
        }
        .cta-close{
            font-size:14px;
            color: #e1e1e1;
            text-decoration: underline;
            cursor: pointer;
        }
        &.danger{

            border-color: #ff4444;
            .modal_title{
                color: #ff4444;

            }
            .cta{
                background-color: #ff4444;
                &:hover{
                    transform: scale(0.98);
                    box-shadow: unset;
                    -webkit-box-shadow: unset;
                    -moz-box-shadow: unset;
                    opacity:0.75;
                }
            }
        }
    }
}

