@import '../../styles/mediaqueries';

.signup{

    width: 100%;
    min-height: 100vh;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #202020;

    padding-bottom: 100px;
    padding-top: 32px;
    
    /*
    position:fixed;
    width: 100vw;
    height: 100vh;
    top: 79px;
    left: 0;
    z-index: 3;
    */
    &.visible{
        display: flex;
    }
    .close-btn{
        position: absolute;
        transform: rotate(45deg);
        cursor: pointer;
        top: 120px;
        right: 120px;
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: #7a7575;

        @include media(md){
            right:20px;
            top: 120px;
        }
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: calc(352px + 200px);
        width: calc(100% - 20px);
        padding: 50px 100px;

        @include media(md){
            padding: 32px 10px;
        }
        .step{
            font-size: 14px;
            color: #d2d2d2;
            line-height: 16px;
        }
        .title{
            font-size: 32px;
            color: #fff;
            line-height: 40px;
        }
        .input-name{
            font-size: 12px;
            font-weight: 400;
            width: 100%;
            text-align: left;
            color: #FFF;
        }
        .input-text{
            width: 100%;
            border-radius: 12px;
            height: 45px;
            font-size: 16px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            color: #555;
            background-color: #fff;
            border: 1px solid #858585;
        }
        .newsletter{
            display: flex;
            align-items:center;
            gap:4px;
            color: #f68802;
            font-size: 12px;
            line-height: 24px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }

        .error-msg{
            color: red;
            font-size:12px;
        }
        .cta{
            color: white;
            border: 0;
            font-size: 22px;
            font-weight: normal;
            cursor: pointer;
            transition: all 0.2s ease;
            display: block;
            display: block;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #f68802;
            border-radius: 30px;
            padding: 8px;
            cursor: pointer;
            &:hover{
                transform: scale(1.03);
                box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                -webkit-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                -moz-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
            }
        }
        .separator{
            font-size: 24px;
            text-transform: uppercase;
            color: #FFF;
        }
        .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 30px;
            width: 100%;
            position:relative;
            cursor: pointer;
            transition: all 0.2s ease;
            height: 54px;
            .icon{
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 12px;
                border-radius: 50%;
            }
            &.cta-facebook{
                color: white;
                background-color: #1977f3;
                .icon{
                    width: 32px;
                    height: 32px;
                }
            }
            &.cta-google{
                color: #2e2e2e;
                background-color: white;
            }
            &:hover{
                text-decoration: underline;
            }
        }
        .links{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #a1a1a1;
            gap:4px;
            font-size: 15px;
            cursor: pointer;
            .link{
                cursor: pointer;
                &.small{
                    font-size: 12px;
                    text-decoration: underline;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: rgba(0,0,0,0);
        /* Not removed via appearance */
        margin: 0;
        
        font: inherit;
        color: rgba(0,0,0,0);
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid #cdcdcd;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        
        display: grid;
        place-content: center;
        cursor:pointer;
    }
    
    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #f68802;
        /* Windows High Contrast Mode */
        background-color: #f68802;
    }
    
    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
    
    input[type="checkbox"]:focus {
        outline: max(2px, 0.15em) solid rgba(0,0,0,0);
        outline-offset: max(2px, 0.15em);
    }

}










