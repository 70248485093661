

/* This file meant to have only utility classes, only for atomic classes

   similar concept as used in tail-wind
*/



/* Margins */

.m-v-0{ margin-top: 0; margin-bottom: 0; }
.m-h-0{ margin-left: 0; margin-right: 0; }
.m-h-auto, .m-h-a{ margin-left: auto; margin-right: auto; }

.m-t-a{ margin-top: auto; }
.-m-t-16{ margin-top: -16px; }
.-m-t-8{ margin-top: -8px; }
.m-t-0{ margin-top: 0px; }
.m-t-12{ margin-top: 12px; }
.m-t-16{ margin-top: 16px; }
.m-t-18{ margin-top: 18px; }
.m-t-24{ margin-top: 24px; }
.m-t-32{ margin-top: 32px; }
.m-t-48{ margin-top: 48px; }
.m-t-80{ margin-top: 80px; }
.m-t-124{ margin-top: 124px; }
.m-t-191{ margin-top: 191px; }

.m-b-auto, .m-b-a{ margin-bottom: auto; }
.-m-b-4{ margin-bottom: -4px; }
.m-b-6{ margin-bottom: 4px; }
.m-b-8{ margin-bottom: 6px; }
.m-b-8{ margin-bottom: 8px; }
.m-b-10{ margin-bottom: 10px; }
.m-b-11{ margin-bottom: 11px; }
.m-b-12{ margin-bottom: 12px; }
.m-b-14{ margin-bottom: 14px; }
.m-b-16{ margin-bottom: 16px; }
.m-b-20{ margin-bottom: 20px; }
.m-b-24{ margin-bottom: 24px; }
.m-b-28{ margin-bottom: 28px; }
.m-b-30{ margin-bottom: 30px; }
.m-b-32{ margin-bottom: 32px; }
.m-b-36{ margin-bottom: 36px; }
.m-b-40{ margin-bottom: 40px; }
.m-b-41{ margin-bottom: 41px; }
.m-b-48{ margin-bottom: 48px; }
.m-b-56{ margin-bottom: 56px; }
.m-b-64{ margin-bottom: 64px; }
.m-b-68{ margin-bottom: 68px; }
.m-b-84{ margin-bottom: 84px; }
.m-b-92{ margin-bottom: 92px; }
.m-b-96{ margin-bottom: 96px; }
.m-b-120{ margin-bottom: 120px; }
.m-b-134{ margin-bottom: 134px; }
.-m-b-120{ margin-bottom: -120px; }
.-m-b-200{ margin-bottom: -200px; }


.m-r-4{ margin-right: 4px; }
.m-r-8{ margin-right: 8px; }
.m-r-11{ margin-right: 11px; }
.m-r-12{ margin-right: 12px; }
.m-r-14{ margin-right: 14px; }
.m-r-16{ margin-right: 16px; }
.m-r-24{ margin-right: 24px; }
.m-r-32{ margin-right: 32px; }
.m-r-35{ margin-right: 35px; }
.m-r-a{ margin-right: auto; }

.m-l-a{ margin-left: auto; }
.m-l-4{ margin-left: 4px; }
.m-l-8{ margin-left: 8px; }
.m-l-12{ margin-left: 12px; }
.m-l-16{ margin-left: 16px; }

@media (max-width: 930px) {
    
    .sm\:m-v-0{ margin-top: 0; margin-bottom: 0; }
    
    .sm\:m-t-0{ margin-top: 0; }
    .sm\:m-t-40{ margin-top: 40px; }
    .sm\:m-t-190{ margin-top: 190px; }
    .sm\:m-t-247{ margin-top: 247px; }
    .sm\:m-t-335{ margin-top: 335px; }
    
    .sm\:m-t-90vw{ margin-top: 90vw; }
    
    .sm\:m-b-0{ margin-bottom: 0; }
    .sm\:m-b-8{ margin-bottom: 8px; }
    .sm\:m-b-16{ margin-bottom: 16px; }
    .sm\:m-b-20{ margin-bottom: 20px; }
    .sm\:m-b-24{ margin-bottom: 24px; }
    .sm\:m-b-32{ margin-bottom: 32px; }
    .sm\:m-b-40{ margin-bottom: 40px; }
    
    .sm\:-m-b-245{ margin-bottom: -245px; }
}
@media (max-width: 735px) {
    .xs\:-m-t-8{ margin-top: -8px; }
    .xs\:m-t-0{ margin-top: 0; }
    .xs\:m-t-8{ margin-top: 8px; }
    .xs\:m-t-12{ margin-top: 12px; }
    .xs\:m-t-16{ margin-top: 16px; }
    .xs\:m-t-32{ margin-top: 32px; }

    .xs\:m-b-a{ margin-bottom: auto; }
    .xs\:m-b-0{ margin-bottom: 0; }
    .xs\:m-b-2{ margin-bottom: 2px; }
    .xs\:m-b-6{ margin-bottom: 6px; }
    .xs\:m-b-7{ margin-bottom: 7px; }
    .xs\:m-b-8{ margin-bottom: 8px; }
    .xs\:m-b-16{ margin-bottom: 16px; }
    .xs\:m-b-19{ margin-bottom: 19px; }
    .xs\:m-b-20{ margin-bottom: 20px; }
    .xs\:m-b-22{ margin-bottom: 22px; }
    .xs\:m-b-24{ margin-bottom: 24px; }
    .xs\:m-b-32{ margin-bottom: 32px; }
    .xs\:m-b-40{ margin-bottom: 40px; }
    .xs\:m-b-48{ margin-bottom: 48px; }
    .xs\:m-b-60{ margin-bottom: 60px; }
    .xs\:m-b-64{ margin-bottom: 64px; }
    
    .xs\:m-r-0{ margin-right: 0px; }
    .xs\:m-r-4{ margin-right: 4px; }
    .xs\:m-r-12{ margin-right: 12px; }
    .xs\:m-r-16{ margin-right: 16px; }
    
    .xs\:m-l-a{ margin-left: auto; }
    
    .xs\:m-h-a{ margin-left: auto; margin-right: auto; }
}

.mobile-only{
    display: none !important;
}
.desktop-only{
    display: flex !important;
}

@media (max-width: 735px) {
  .mobile-only{
        display: flex !important; 
    }
    .desktop-only{
        display: none !important;
    }
  
}

