



.button-cta{

    color: white;
    border: 0;
    font-size: 22px;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    display: block;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 352px;
    border-radius: 30px;
    
    padding: 8px;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    
    
    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;
    background-size: 100% 100%;
    
    

    @keyframes animatedgradient {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    &:hover{
        //background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #a166ab, #5073b8);
        background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #ec2c01,#E23B17, #F36C17, #f7b722, #f7f022 );
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
        /*
        box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
        -webkit-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
        -moz-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
        */
    }
    &.disabled{
        opacity: 0.8;
        cursor: auto;
    }
    &.secondary{
        background: none;
        font-size: 16px;
        &:hover{
            text-decoration: underline;
            background:rgb(30, 30, 30);
        }
    }



}



