


.results{

    width: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #151514;
    padding: 64px 0;
    gap: 16px;
    .item{
        width: 220px;
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: #FFF;

        gap: 8px;
        .name{
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 4px;
        }
        &:hover{
            .name{
                text-decoration: underline;
            }
        }
    }

    .clear-container{
        width:100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        button{
            padding: 8px;
            min-width: 200px;
            border: 1px solid #FFF;
            border-radius: 30px;
            color: #FFF;
        }
    }
}



