

.questionnaire{

    .form{
        font-size: 18px;
        line-height: 26px;

        .questions{
            font-size: 16px;
            line-height: 26px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            label{
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 8px
            }
            textarea{
                border:0;
                padding: 0px;
                max-height: 0;
                overflow: hidden;
                transition: all 200ms ease-in-out;
                &.visible{
                    padding: 8px;
                    max-height: 100px;
                }
            }
        }
    }
}