@import '../../styles/mediaqueries.scss';


.search-component{
    position: relative;
    //background-color: #1f20271f;
    
    width: 100%;

    .shadow{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;

        background: rgba(0,0,0, 0.12);
        backdrop-filter: blur(4px);
        z-index: 1;
        display: none;
    }
    &.focused{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999999;
        
        .shadow{
            display: block;
        }
        .btn_close{
            display: flex;
        }
        .search-modal{
            display: flex;
        }
        .search-bar_2{
            position: absolute;
            top: 16px;
        }
    }
    .search-bar_2{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        cursor: pointer;
        gap:4px; 
        background: #0b0c10;
        border-color: #0b0c10;
        flex:1;
        max-width: 300px;
        width: 100%;
        transition: max-width 250ms cubic-bezier(.4,0,.2,1);
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        top:0;
        left:0;
        right: 0;
        margin: auto;
        z-index:4;
        box-shadow: 0 0 8px 0px rgba(150, 150, 150, 0.6);
        @include media(md2){
            position: relative;
            width:100%;
            max-width: 100%;
            margin:16px 0;
            order: 2;
            flex:unset;
        }
        
        .logo-search{
            //filter: grayscale(1) brightness(0) invert(1);
            margin-left: 12px;
            margin-right: 12px;
            width: 30px;
            opacity: 0;
            display: none;
            transition: max-width 250ms cubic-bezier(.4,0,.2,1);
        }
        &.focused{
            max-width: 600px;
            animation: makeVisible 1ms linear 600ms forwards;
            @include media(xl){
                width: calc(100% + 5px);
                max-width: calc(100% + 5px);
            }
            .chip{
                margin-left: 6px;
            }
            .logo{
                @include media(xl){
                    display: flex;
                    opacity: 1;
                }
                @include media(md2){
                    display: none;
                }
            }
        }
        @keyframes makeVisible {
            from {
                overflow: hidden;
            }
            to {
                overflow: visible;
            }
        }                  
        .chip{
            display: flex;
            align-items:center;
            justify-content: flex-start;
            gap: 1em;
            
            background: #3f404f;
            color: #d6cbcb;
            border-radius: 32px;
            padding: 0 16px;
            height: 100%;
            max-height: calc(100% - 12px);
            margin: 6px;
            font-size: 16px;
            min-width: 170px;
            margin-left: -180px;
            transition: all 250ms cubic-bezier(.4,0,.2,1);
            //transition-delay: 150ms;
            align-self: flex-start;
            position: relative;
            overflow: hidden;
            @include media(md2){
                display: none;
            }
            .chevron-icon{
                font-size: 6.4px;
                transition: all 300ms cubic-bezier(.4,0,.2,1);
            }
            &.expanded{
                overflow: visible;
                .options{
                    opacity: 1;
                }
                .chevron-icon{
                    transform: rotate(-180deg);
                }
            }
            .options{
                display: flex;
                flex-direction: column;
                gap:16px;
                align-items: flex-start;
                justify-content: flex-start;
                border-radius: 4px;
                background: #1f2027;
                transition: all 550ms cubic-bezier(.4,0,.2,1);
                position: absolute;
                top: calc(100% + 16px);
                left:0;
                right:0;
                margin: auto;
                width: 100%;
                height: max-content;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                padding: 3.2px;
                gap: 8px;
                opacity: 0;
                z-index:1002;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
                .item{
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    justify-content: space-between;
                    padding:6.4px;
                    border-radius: 4px;
                    background: #2c2d36;
                    &:hover{
                        background: #3f404f;
                        
                    }
                    &.selected{
                        background: #5e5e5e;
                        background: #3f404f;
                        &:hover{
                            background: #3f404f;
                        }
                    }
                    span{
                        font-size: 12.8px;
                    }
                    .glyphicon{
                        color: #9e9cb0;
                    }
                }
            }
        }
        .search-bar-input{
            padding: 6px 12px;
            flex:1;
            max-width: 500px;
            background: none;
            border:none;
            outline: none;
            font-size: 16px;
            font-family: 16px 'Rajdhani', sans-serif;
            color: #d6cbcb;
            margin-bottom: 0;
            order:1;
            @include media(xl){
                max-width: 70%;
            }
            @include media(md2){
                max-width: unset;
            }
        }
        .close{
            opacity: 0;
            order: 2;
            color:#d6cbcb;
            font-size: 9.6px;
            transition: all 550ms cubic-bezier(.4,0,.2,1);
            &.visible{
                opacity:1;
            }
        }
        .form-btn{
            color: #fff;
            background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
            //border-color: #dd7a02;
            border: none;
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-top-right-radius: 32px;
            border-bottom-right-radius: 32px;
            order: 3;

            transition:background-position 1000ms ease;
            &:hover{
                //background-color: #ec8202;
                background-size: 400% 400%;
                background-position:100% 50%;
                //background: linear-gradient(123deg, #E23B17 25.9%, #F36C17 89.26%);
                //border-color: #d37402;

            }
            &.btn-setting{
                order:2;
                border-radius: 0;
                margin-left: auto;
                border-right: 1px solid #563205;

                &.active{
                    & + .submenu{
                        max-height: 200px;
                        border-width: 1px;
                        padding: 5px 0;
                    }
                }
            }
            &.btn-search{
                background: transparent;
                margin-left:auto;
                margin-right: 12px;
            }
        }
        .submenu{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            position: absolute;
            right:0;
            top:100%;
            color: #fff;
            background-color: #333;
            border: 1px solid #808080;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            box-shadow: 2px 6px 8px -1px rgba(0, 0, 0, 0.5);
            font-size: 14px;
            z-index:3;
            gap:2px;
            overflow: hidden;
            transition: all 200ms ease-in-out;
            max-height: 0;
            border-width: 0;

            .item{
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                padding:4px;
                cursor: pointer;
                &:hover{
                    color:#F36C17;
                }
                &:before{
                    content: "\e817";
                    font-family: "crateicons";
                    margin-right: 4px;
                }
                &.checked{
                    &:before{
                        content: "\e81f";
                    }
                }
            }
        }
    }

    .btn_close{
        position: fixed;
        top: 24px;
        right:2%;
        cursor: pointer;
        color: #FFF;
        padding: 4px;
        font-size: 24px;
        z-index:3;
        display: none;
    }
    .ais-SearchBox {
        position: absolute;
        z-index: 10;
        top:60px;
        left:0;
        right:0;
        margin: auto;
        width: 500px;
        max-width: calc(100% - 20px);
        background-color: #f0f0f0;
        padding: 10px;
        border-radius: 4px;
    }
    .search-modal {
        display: none;
        position: absolute;
        gap:24px;
        top: 80px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1000;
        border-radius: 12px;
        background:#0c0c11;
        padding: 24px;
        width: calc(100% - 40px);
        height: calc(100vh - 96px);
        overflow: scroll;
        max-width: 1920px;
        &.hide {
            display: none;
        }
        
        .search-results-container{
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }
        .search-results{
            display: flex;
            position: relative;
            flex-wrap: wrap;
            gap: 12px;
            //justify-self: stretch;
            flex-grow: 1;
            width: 100%;
        }
        .ais-InfiniteHits-item{
            width: max-content;
        }
        .search-load-more, .filters-show-more, .filters-clear{
            background-image: linear-gradient(123deg, #f36c17 10%, #e23b17 30% 45%, #f38e17 55% 70%, #e26c17);
            background-size: 300% 100%;
            border-color: transparent;
            color: #fff;
            min-width: 120px;
            cursor: pointer;
            border-radius: 4px;
            position: relative;
            margin: 16px;
            min-height: 32px;
        }
        .search-no-results{
            
            .search-load-more{
                display: none;
                
            }
        }
        .ais-RefinementList-showMore{
            margin:16px 0;
        }
        .filters-show-more--disabled{
            display: none;
        }
        .filters-clear{
            margin:0;
        }
        .filters-sortby{
            background:none;
        }
        .filters-sortby--select{
            background:#272830;
            padding:2px 8px ;
            width: 200px;
            color:#9595a2;
            border:none;
            border-radius:2px;
        }
        .search-no-results{

        }
        .search-filters{
            display: flex;
            flex-direction: column;
            position: sticky;
            top:0px;
            z-index: 2000;
            min-width: 300px;
            padding: 12px 24px;
            background: #1d1d29;
            color: #FFF;
            border-radius: 12px;
            height: 100%;
            overflow-y: auto;
        }
        .filters-list--scrollable{
            flex:1;
            overflow-y: auto;
        }
        .search-item{
            width: 248px;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 4px;
            color: #FFF;
            font-size:12px;
            font-weight: 400;
            background-color: transparent;
            padding: 0px;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            position: relative;
            .video_pic{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px;
            }
            //.showable{
               // position: relative;
                //padding-bottom: 12px;
                .asset_level{
                    display: none;
                    position: absolute;
                    left:8px;
                    top: 8px;
                    color:#ffb653;
                    font-size: 16px;
                }
                .asset_popularity{
                    display: flex;
                    position:absolute;
                    top: 8px;
                    right:8px;
                    mix-blend-mode: difference;
                    color: white; 
                }
                .asset_created{
                    display: flex;
                    width: max-content;
                    position:absolute;
                    top: 12px;
                    left:0;
                    right:0;
                    margin:auto;
                    color: red; 
                    font-size: 12px;
                }
                .asset_name{
                    display: none;
                    position:absolute;
                    bottom: 12px;
                    left:8px;
                    mix-blend-mode: difference;
                    color: white; 
                    font-size: 16px;
                }
                &:hover{
                    .asset_name, .asset_level{
                        display: flex;
                    }
                }
            //}
        }
    }
    
    
    
    
}
