

.survey{
    font-family: Rajdhani;
    color: #FFF;
    display: none;
    
    &.visible{
        display: flex;
    }
    .shadow{
        width: 100%;
        height: 100%;
        position:fixed;
        left:0;
        top:0;
        z-index: 10000;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(4px);
    }
    .modal{
        background: #14151c;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .06);
        position: fixed;
        overflow: hidden;
        z-index: 10001;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 40px);
        max-height: calc(100% - 40px);
        width: max-content;
        height: max-content;
        min-width: 400px;
        min-height: 296px;
        margin: auto;
        top:0;
        bottom:0;
        left:0;
        right:0;
        padding: 16px;
        border-radius: 8px;
        transition: all 0.4s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
        animation: fadeInUp 0.4s cubic-bezier(0.4, 0.0, 0.2, 1) forwards;
        .close{
            position: absolute;
            top:10px;
            right:10px;
            cursor: pointer;
            color:#c4c4c4;
            font-size:18px;
            font-family: Rajdhani;
            transform:scaleX(1.5);
        }
        .progress_bar{
            width: 100%;
            height: 4px;
            position: absolute;
            top:0;
            left:0;
            overflow: hidden;

            .progress {
                background-color: #c4c4c4;
                height: 100%;
                width: 33%;
                transition: width 0.5s ease;
            }  
        }
        .question{
            font-size: 1rem;
            line-height: 1;
            letter-spacing: -.025em;
            max-width: 80%;
            &.accent{
                font-size: 1.125rem;
                font-weight: 600;
            }
        }
        .column_wrapper{
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            max-height: 300px;
            flex-wrap: wrap;
        }
        .thankYou{
            line-height: 1;
            letter-spacing: -.025em;
            font-size: 1.125rem;
            font-weight: 600;
            width: 100%;
            text-align: center;
            margin-top: auto;
            margin-bottom: 0;
        }
        .cta{
            max-width: 200px;
            padding: 4px 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            color: #FFF;
            cursor: pointer;
            background-size: 300% 100%;
            background-image: linear-gradient(123deg, #f36c17 10%, #e23b17 30% 45%, #f38e17 55% 70%, #e26c17);
            margin: 32px auto auto auto;
            font-weight: 700;
            text-transform: uppercase;

            &.disabled {
                background-image: linear-gradient(123deg, #f36c17 10%, #e23b17 30% 45%, #f38e17 55% 70%, #e26c17);
                background-size: 300% 100%;
                background-position: 100% 0;
                transition: background-position 0.5s, opacity 0.5s;
                pointer-events: none;
                opacity: 0.5;
            }  
        }
        .back{
            color: #FFF;
            font-size: 14px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        .answer{
            padding: 6px 8px;
            background: #272830;
            border-radius: 4px;
            cursor: pointer;
            transition: all 200ms ease-in-out  ;
            &:hover{
                background: #65666f;
                padding-left: 12px;
                padding-right: 2px;
            }
            &.selected{
                background: #9798a2;
                padding-left: 12px;
                padding-right: 2px;
            }
        }
    }
}


@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    60% {
        opacity: 1;
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}
