@import './reset';
@import './utility';
@import './icons';
@import './mediaqueries';
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
  font-size: 16px; // Base font size for most screens
}

@media (min-width: 930px) and (max-width: 1024px) {
  html {
      font-size: 16px; 
  }
}
@media (min-width: 1920px) {
  html {
      font-size: 18px;
  }
}
@media (min-width: 2560px) {
  html {
      font-size: 22px;
  }
}
@media (min-width: 2704px) {
  html {
      font-size: 24px; 
  }
}

body {
  margin: 0;
  font-family: 'Rajdhani', sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}
