



.input-multiple{

    display: flex;
    align-items: center;
    gap:6px;
    width: 100%;

    span{
        padding: 8px;
        background-color: #2f2f2f;
        color: #fff;
        border: 1px solid #000;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        min-width: max-content;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input, select {
        background-color: #222;
        color: #fff;
        border: 1px solid #000;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
    }

    

}