@import '../../styles/mediaqueries';

.cta {
    z-index: 1;
    border: solid 0.1875rem #14151C; /* 3px to rem */
    font-size: 1.4066rem; /* 22.505px to rem */
    font-style: normal;
    font-weight: 700;
    line-height: 1.8848rem; /* 30.157px to rem */
    letter-spacing: 0.0703rem; /* 1.125px to rem */
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    //border-radius: 0.375rem; /* 6px to rem */
    border-radius: 2rem;
    //padding: 0.75rem 1.5rem; /* 12px 24px to rem */
    padding: 0.7rem 2.5rem;
    cursor: pointer;

    @keyframes animatedgradient {
        0% { background-position: 0% 50% }
        50% { background-position: 80% 50% }
        100% { background-position: 0% 50% }
    }

    &:hover {
        transform: scale(1.03);
        border: 0.1875rem solid rgba(255, 255, 255, 0); /* 3px to rem */
        color: #F4F4F4;
        background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #ec2c01, #E23B17, #F36C17, #f7b722, #f7f022);
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
    }

    @include media(md){
        width: 90%;
    }

    &.accent {
        background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
        border: 0.1875rem solid #F4F4F4; /* 3px to rem */
        color: #F4F4F4;

        @media (min-width: md2) {
            padding-bottom: 3.75rem; /* 60px to rem */
        }
    }

    &.super_accent {
        background: none;
        border: 0.1875rem solid #F4F4F4; /* 3px to rem */
        color: #F4F4F4;

        &:hover {
            background: none;
            border: 0.1875rem solid #F4F4F4; /* 3px to rem */
        }
    }

    &.disabled {
        background: #c0bcbc !important;
        color: #F4F4F4;
        border: 0.1875rem solid #F4F4F4; /* 3px to rem */
        cursor: not-allowed !important;

        &:hover {
            background: #F4F4F4;
            color: #14151C;
            border: 0.1875rem solid #F4F4F4; /* 3px to rem */
            transform: scale(1);
        }
    }
    &.modern{
        border-color: transparent;
        border-radius: 6px;
        padding: 4px 8px;
        font-size: 16px;
        font-weight: 500;
        border-width: 2px;
    }
    &.white{
        color: #FFF;
        border-color: #FFF;

        &:hover{
            color: #14151C;
            background: #FFF;
        }
    }
}

