// Mediaquerys:
$breakpoint-xs: 375px;
$breakpoint-sm: 429px;
$breakpoint-sm-2: 530px;
$breakpoint-md: 735px;
$breakpoint-md-2: 930px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-2xl: 1600px;
$breakpoint-3xl: 1900px;
$breakpoint-4xl: 1900px;
$breakpoint-5xl: 1900px;

@mixin media($media) {
  @if $media == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  }
  @if $media == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == sm2 {
    @media (max-width: $breakpoint-sm-2) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == md2 {
    @media (max-width: $breakpoint-md-2) {
      @content;
    }
  }
  @if $media == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  }
  @if $media == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  }
  @if $media == 2xl {
    @media (max-width: $breakpoint-2xl) {
      @content;
    }
  }
  @if $media == 3xl {
    @media (max-width: $breakpoint-3xl) {
      @content;
    }
  }
}
