@import '../../styles/mediaqueries';

body{
    background-color: #151514;
}
.plans_v2 {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 6.25rem;
    //background-color: #151514;
    font-family: Rajdhani;

    &.black-friday{
        .title, .subtitle{
            z-index: 2;
        }
    }
    .title {
        color: #ffffff;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem; /* 82.519px */
        letter-spacing: 0.0352rem;
        opacity: 1;
        transition: all 200ms ease-in-out;

        &.hidden {
            opacity: 0;
        }

        @include media(md2) {
            padding: 0 0.5rem;
            text-align: center;
            font-size: 3rem;
        }
    }

    .subtitle {
        color: #ffffff;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 82.519px */
        letter-spacing: 0.0352rem;
        max-width: 700px;
        text-align: center;
        .accent {
            color: #F36C17;
        }

        @include media(md2) {
            padding: 0 0.5rem;
            text-align: center;
        }
    }

    .switch {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        cursor: pointer;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;

        &.black-friday{
            .bar .circle{
                background: linear-gradient(45deg, #ff0000, #c400ff, #ff007f);
            }
        }
        .option {
            cursor: pointer;
            background: var(--Linear, linear-gradient(157deg, #F4F4F4 22.38%, #6F8090 193.12%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.14375rem;
        }

        .bar {
            border-radius: 24px; /* 40px * 0.6 */
            padding: 0.075rem; /* 0.125rem * 0.6 */
            width: 3.1875rem; /* 5.3125rem * 0.6 */
            height: 1.6875rem; /* 2.8125rem * 0.6 */
            background: #FFF;
            display: flex;
            position: relative;
        
            @media (min-width: md) {
                width: 2.25rem; /* 3.75rem * 0.6 */
                height: 1.125rem; /* 1.875rem * 0.6 */
            }
        
            .circle {
                background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                border-radius: 50%;
                width: 1.3125rem; /* 2.1875rem * 0.6 */
                height: 1.3125rem; /* 2.1875rem * 0.6 */
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                transition: all 200ms ease;
        
                @media (min-width: md) {
                    width: 0.9375rem; /* 1.5625rem * 0.6 */
                    height: 0.9375rem; /* 1.5625rem * 0.6 */
                }
            }
        
            &.left {
                .circle {
                    left: 0.1875rem; /* 0.3125rem * 0.6 */
                }
            }
        
            &.right {
                .circle {
                    left: calc(100% - 1.5rem); /* calc(100% - 2.5rem) * 0.6 */
        
                    @media (min-width: md) {
                        left: calc(100% - 1.125rem); /* calc(100% - 1.875rem) * 0.6 */
                    }
                }
            }
        }
    }

    .list {
        width: calc(100vw - 3rem); //calc(100% - 1.25rem);
        padding-top: 1rem;
        display: flex;
        align-items: stretch;
        gap: 1.5rem;
        justify-content: center;
        opacity: 0;

        overflow-y: visible;
        overflow-x: hidden;
        //flex-wrap: wrap;
        transform: translateY(2.5rem);
        transition: all 300ms ease;
        min-height: 25rem;

        @include media(md){
            width: 100%;
            
            transition: none;
            margin-bottom: 2rem;
            justify-content: flex-start;
            transform: translateY(0.5rem);
        }
        @include media(lg) {
            flex-wrap: wrap;
            min-height: unset;
            justify-content: center;
        }

        @include media(md2) {
            flex-direction: column;
            align-items: center;
            height: auto;
            min-height: unset;
            transform: translateY(0.5rem) !important;
        }
        &.visible {
            opacity: 1;
            transform: translateY(0px);
        }

        &.summarized {
            @include media(md2) {
                transform: unset;
                //width: calc(100% - 2.5rem);
            }
        }

        &.black-friday{
            overflow: visible;
        }
        

        .plan {
            position: relative;
            color: #14151C;
            background-color: #F4F4F4;
            border-radius: 16px;
            max-width: 19rem;
            width: 18rem;
            //width: max-content;
            padding: 1.75rem 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            transition: all 300ms ease;
            opacity: 1;

            @include media(md2) {
                max-width: 25rem;
                min-width: unset;
                width: 100%;
                margin-top: 0;
                margin-right: 0 !important;
                transform: none !important;
                //padding-bottom: 3.75rem;
                padding-bottom: 1rem;
                transition: none;
                border-radius: 0;
                padding-left: 2rem;
                padding-right: 2rem;
            }
            &.with_toggle{
                @include media(md2) {
                    padding-bottom: 3.75rem;
                }
            }
            &.hidden {
                @include media(md2) {
                    opacity: 0;
                    padding: 0;
                    overflow: hidden;
                    max-height: 0;
                }
            }

            &.accent {
                background: var(--Gradient-1, linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%));
                position: relative;

                &.hidden {
                    @include media(md2) {
                        display: none;
                    }
                }
            }

            &.super_accent {
                color: #F4F4F4;

                .old-price {
                    @include media(md2) {
                        display: none;
                    }
                }

                .price {
                    color: #F4F4F4;
                }

                @include media(md2) {
                    display: flex;
                }
            }

            @keyframes glow-pulse2 {
                0% {
                  box-shadow: 0 0 40px 15px rgba(255, 0, 0, 0.4), 
                              0 0 60px 25px rgba(196, 0, 255, 0.4),
                              0 0 80px 40px rgba(255, 0, 127, 0.4);
                }
                50% {
                  box-shadow: 0 0 70px 30px rgba(255, 0, 0, 0.6), 
                              0 0 100px 50px rgba(196, 0, 255, 0.5),
                              0 0 130px 70px rgba(255, 0, 127, 0.5);
                }
                100% {
                  box-shadow: 0 0 40px 15px rgba(255, 0, 0, 0.4), 
                              0 0 60px 25px rgba(196, 0, 255, 0.4),
                              0 0 80px 40px rgba(255, 0, 127, 0.4);
                }
            }

            @keyframes glow-pulse-out {
                0% {
                  box-shadow: 0 0 30px 10px rgba(255, 0, 0, 0.4), 
                              0 0 50px 20px rgba(196, 0, 255, 0.4),
                              0 0 70px 30px rgba(255, 0, 127, 0.4);
                }
                100% {
                    box-shadow: 0 0 10px 3px rgba(255, 0, 0, 0.4),
                    0 0 20px 7px rgba(196, 0, 255, 0.4),
                    0 0 30px 10px rgba(255, 0, 127, 0.4);
                }
            }

            @keyframes glow-pulse {
                0% {
                  box-shadow: 0 0 40px 15px rgba(255, 0, 0, 0.4),
                              10px -10px 60px 25px rgba(196, 0, 255, 0.4),
                              -10px 10px 80px 40px rgba(255, 0, 127, 0.4);
                }
                25% {
                  box-shadow: 0 0 50px 20px rgba(255, 0, 0, 0.5),
                              -15px 15px 70px 35px rgba(196, 0, 255, 0.5),
                              15px -15px 100px 50px rgba(255, 0, 127, 0.5);
                }
                50% {
                  box-shadow: 0 0 70px 30px rgba(255, 0, 0, 0.6),
                              20px -20px 100px 50px rgba(196, 0, 255, 0.6),
                              -20px 20px 130px 70px rgba(255, 0, 127, 0.6);
                }
                75% {
                  box-shadow: 0 0 50px 20px rgba(255, 0, 0, 0.5),
                              15px -15px 70px 35px rgba(196, 0, 255, 0.5),
                              -15px 15px 100px 50px rgba(255, 0, 127, 0.5);
                }
                100% {
                  box-shadow: 0 0 40px 15px rgba(255, 0, 0, 0.4),
                              -10px 10px 60px 25px rgba(196, 0, 255, 0.4),
                              10px -10px 80px 40px rgba(255, 0, 127, 0.4);
                }
              }

            &.black-friday {
                background:linear-gradient(45deg, #ff0000, #c400ff, #ff007f);
                color: #FFF;
                
                transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
                box-shadow: 0 0 30px 10px rgba(255, 0, 0, 0.4), 
                0 0 50px 20px rgba(196, 0, 255, 0.4),
                0 0 70px 30px rgba(255, 0, 127, 0.4);

                animation: glow-pulse 6s infinite ease-in-out;

                &:hover{
                    box-shadow: 0 0 10px 3px rgba(255, 0, 0, 0.4),
                    0 0 20px 7px rgba(196, 0, 255, 0.4),
                    0 0 30px 10px rgba(255, 0, 127, 0.4);
                    animation: none;
                    animation: glow-pulse-out 200ms ease-out;
                }
                .bg_whiter {
                    background: #151514;
                    background: linear-gradient(180deg, #1a1a1a, #151514);
                }
                .cta.accent{
                    background:linear-gradient(45deg, #ff0000, #c400ff, #ff007f);
                    border:none;

                    background: linear-gradient(45deg, #ff0000, #c400ff, #ff007f);
                    border: none;

                    &:hover {
                        background: linear-gradient(45deg, #c400ff, #ff007f, #ff0000);
                        //box-shadow: 0 0 10px 5px rgba(255, 0, 127, 0.6);
                    }
                }
                .price{
                    color: #FFF;
                    text-shadow: 0 0 5px rgba(255, 0, 0, 0.5), 0 0 10px rgba(196, 0, 255, 0.5);
                }
            }

            .bg_whiter {
                position: absolute;
                width: calc(100% - 0.75rem);
                height: calc(100% - 0.75rem);
                top: 0.375rem;
                left: 0.375rem;
                border-radius: 16px;
                z-index: 0;
                background: #F4F4F4;
                @include media(md){
                    border-radius: 0;
                }
            }

            .name {
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.14375rem;
                z-index: 1;

                @include media(md2) {
                    font-size: 2rem;
                }
            }

            .old-price {
                font-size: 1.375rem;
                text-decoration: line-through;
                z-index: 1;
            }

            .price {
                color: var(--Primary-400, #272830);
                font-size: 2.3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.9rem;
                letter-spacing: 0.0197rem;
                z-index: 1;

                @include media(md) {
                    font-size: 2.25rem;
                    line-height: 2.625rem;
                }

                .accent {
                    font-size: 3.8rem;
                    letter-spacing: 0.0352rem;
                    display: inline-flex;

                    @include media(md) {
                        font-size: 2.625rem;
                    }

                    .ticker-digit {
                        text-align: center;
                    }
                }
            }

            .note {
                z-index: 1;
                font-size: 1.175rem;
                font-weight: 500;
                line-height: 2.14375rem;
                text-align: center;
                transition: opacity 1s ease-in-out;

                @include media(md) {
                    font-size: 1.125rem;
                }
            }

            .features {
                width: 100%;
                min-width: max-content;
                padding: 0;
                padding-bottom: 0;
                align-items: center;
                flex-direction: column;
                display: flex;
                gap: 0rem;

                @include media(md2) {
                    gap: 0.5rem;

                    transition: all 300ms ease;
                    overflow: hidden;
                }
                &.collapsed{
                    @include media(md2) {
                        max-height: 0;
                    }
                }
                &.visible{
                    @include media(md2) {
                        max-height: 300px;
                    }
                }
                .item {
                    display: flex;
                    position: relative;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 1.1875rem;
                    font-weight: 500;
                    line-height: 2.14375rem;
                    position: relative;
                    width: 100%;
                    padding-left: 10%;
                    text-align: left;
                    justify-content: left;
                    cursor: default;

                    @include media(md2) {
                        //text-align: left;
                        //justify-content: left;
                    }
                    
                    .tooltip {
                        width: 100%;
                        position: absolute;
                        bottom: 135%;
                        left: 40%;
                        background-color: rgba(8.2, 8.2, 7.8, 0.8);
                        color: #F4F4F4;
                        line-height: 1.5em;
                        padding: 0.9375rem;
                        text-align: left;
                        border-radius: 6px;
                        font-size: 0.875rem;
                        opacity: 0;
                        transition: opacity 0.2s ease;
                        pointer-events: none;
                        z-index: 14;

                        &:before {
                            content: "\25B2";
                            width: 20px;
                            height: 10px;
                            transform: rotate(180deg);
                            display: inline-block;
                            color: rgba(8.2, 8.2, 7.8, 0.8);
                            position: absolute;
                            bottom: -16px;
                            left: 8%;
                            font-size: 1.125rem;
                            margin-left: -10px;
                            text-align: center;

                            @include media(md2) {
                                left: 50%;
                                margin-left: -0.625rem;
                                bottom: -1.25rem;
                            }
                        }

                        @include media(md2) {
                            left: 0;
                            font-size: 1.25rem;
                            transform: translateY(-6.25rem);
                            bottom: 9.375rem;
                        }
                    }
                    &:hover {
                        .tooltip {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                    a {
                        &:hover {
                            text-decoration: underline;
                        }

                        @include media(md2) {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .show-features{
                display: none;
                align-items: center;
                gap: 0.5rem;
                z-index:2;
                @include media(md){
                    display: flex;
                    cursor: pointer;;
                    //color: black;
                    width: 100%;
                    padding:0.5rem;
                    padding-top:1.5rem;
                    padding-left: 10%;
                    text-align: left;
                    font-size: 1.4rem;
                    &:hover{
                        text-decoration: none;
                    }
                }
                .icon{
                    transform: rotate(90deg);
                    margin-top:-2px;
                    transition: all 0.3s ease;
                }
                &.collapsed{
                    .icon{
                        transform: rotate(-90deg);
                    }
                }
                &.visible{
                    .icon{
                        transform: rotate(90deg);
                    }
                }
            }
            .donate {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 2.1875rem;
                cursor: pointer;
                align-items: center;
                gap: 0.3125rem;
                color: var(--Primary, #14151C);
                text-align: center;
                font-size: 0.9rem;
                font-weight: 500;
                line-height: 1.14375rem;
                position: absolute;
                bottom: 0.625rem;
                left: 0;
                right: 0;
                margin: auto;
                &:hover {
                    //filter: brightness(0.8);
                }
            }

            .subNote {
                width: max-content;
                position: absolute;
                transform: translateY(-50%);
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0.05rem 0.375rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.1875rem;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                //color: #F4F4F4;
                //background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                color: #F36C17;
                background: #F4F4F4;
                border-radius: 6px;
            }
        }
    }

    .custom-claim {
        display: inline-flex;
        color: #EEE;
        font-size: 1.4066rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.14375rem;
        gap: 0.25rem;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 0 0.75rem;

        a {
            text-decoration: underline;
            color: #F36C17;
        }

        @include media(md) {
            font-size: 1.125rem;
        }
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: rgba(0, 0, 0, 0);
        margin: 0;
        font: inherit;
        color: rgba(0, 0, 0, 0);
        width: 1.15rem;
        height: 1.15rem;
        border: 0.15rem solid #14151C;
        border-radius: 50%;
        transform: translateY(-0.075rem);
        display: grid;
        place-content: center;
        cursor: pointer;
    }

    input[type="checkbox"]::before {
        content: "";
        width: 0.65rem;
        height: 0.65rem;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center center;
        transition: 120ms transform ease-in-out;
        background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    input[type="checkbox"]:focus {
        outline: max(2px, 0.15rem) solid rgba(0, 0, 0, 0);
        outline-offset: max(2px, 0.15rem);
    }
}