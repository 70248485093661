.gradient-button {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    padding: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    margin-left: 0rem;
    background-color: #14151c; /* Main background color */
    border: none; /* Remove default border */
    border-radius: 0.3rem; /* Rounded corners */
    font-size: 14px;
    color: white; /* Text color */
    cursor: pointer;
    position: relative;
    z-index: 1; /* Ensure content is above pseudo-elements */
    box-shadow: 0 0 2px #e23b17, 0 0 4px #e23b17, 0 0 7px #f36c17, 0 0 15px #f36c17; /* Glow effect */
    transition: box-shadow 0.3s ease-in-out;
    transition:0.2s;
  }
  
  .gradient-button:hover {
    background-color:#f27718;
    box-shadow: 0 0 4px #f29718, 0 0 8px #f29718, 0 0 20px #f27718, 0 0 30px #f36c17; /* Stronger glow effect on hover */
    transform:scale(1.05);
    transition:0.2s;
  }
  
  .gradient-button::before {
    content: '';
    position: absolute;
    top: -0.15rem;
    left: -0.15rem;
    right: -0.15rem;
    bottom: -0.15rem;
    background: linear-gradient(45deg, #e23b17, #f36c17); /* Gradient background */
    border-radius: inherit;
    z-index: -1; /* Place behind the button */
  }
  
  .gradient-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #14151c; /* Same as button background to create gradient border */
    border-radius: inherit;
    padding: 0.0rem; /* Adjust to create visible border */
    z-index: -1; /* Place behind the button */
  }
  