
body{
    background: #151514;
}

.layout{
    display: flex;
    flex-direction: column;
    background-color: #151514;
    position: relative;
    width: 100%;
    //height: 100vh;
    min-height: 100vh;

    .empty-content{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .general-loading{
        height: 100%;
        top:0;
        width: 100%;
        z-index:2;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        pointer-events: none;
        
        .spinner{
            position:fixed;
            background:rgba(0,0,0,0.01);
            //outline:1px solid red;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
        }
    }
}


