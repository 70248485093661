.particle-system-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Increase the height to allow particles to move freely */
    pointer-events: none;
    overflow: visible; /* Allow particles to go beyond boundaries */
  }
  
  .particle {
    position: absolute;
    width: 10px;
    height: 10px;
    background: radial-gradient(circle, #e23b17 0%, #f36c17 100%);
    border-radius: 50%;
    animation: fadeInOut 2s, rise 2s linear, growShrink 2s;
    opacity: 0;
    z-index: 100;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes rise {
    0% {
      top: 100%;
    }
    100% {
      top: 0%;
    }
  }
  
  @keyframes growShrink {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  