

.footer{

    width:100%;
    padding: 56px 2%;
    background-color: #202020;
    font-size: 11px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    flex-wrap: wrap;
    .column{
        width: max-content;
        
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        row-gap: 8px;

        a{
            min-width: max-content;
            color: #FFF;
            padding: 0 10px;
            border-right: 1px solid #858585;
            font-size: 11px;

            &:last-child{
                border-right: none;
            }
            &:hover{
                color:#F36C17;
                text-decoration: underline;
            }
        }
    }
}








