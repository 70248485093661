@import '../../styles/mediaqueries.scss';


.big_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    text-transform: capitalize;
    width: auto;
    max-width: 100%;
    position: absolute;
    height: 48px; /* 3rem */
    z-index: 1005;
    @include media(md) {
        display: none;
    }
    .tag {
        padding: 1.6px 5.0464px; /* 0.1rem 0.3154rem */
        border-radius: 4px;
        height: max-content;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2.462px;
        background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
        color: var(--Primary-100, #F4F4F4);
        text-align: center;
        font-family: Rajdhani;
        font-size: 9.6px; /* 0.6rem */
        font-style: normal;
        font-weight: 700;
        line-height: 12.369px; /* 134% of 9.6px */
        letter-spacing: 0.462px;
        text-transform: uppercase;
    }
    .top_menu {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        height: calc(18px + 344px + 20px); /* 1.125rem + 21.5rem + 1.25rem */
        //height: auto;
        pointer-events: none;
        width: 100%;
        max-width: 100%;

        //border:2px solid salmon;
        @include media(lg) {
            height: 640px; /* 40rem */
        }
        
        .menu_heading {
            min-width: 64px; /* 4rem */
            position: relative;
            color: #FFF;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px; /* 0.5rem */
            cursor: pointer;
            line-height: normal;
            padding: 0 16px; /* 1rem */
            pointer-events: all;

            //border:2px solid blue;
            @include media(lg) {
                position: unset;
            }
            &:first-of-type {
                border-right: 2px solid #FFF;
                padding-right: 32px; /* 2rem */
                margin-right: 16px; /* 1rem */
            }
            .title {
                z-index: 2;
                content: attr(data-title);
                text-transform: uppercase;
                span {
                    @include media(lg) {
                        display: none;
                    }
                }
                @include media(lg) {
                    &::before {
                        content: attr(data-alternate);
                    }
                }
                &.active {
                    opacity: 1;
                }
                &.disabled {
                    opacity: 0.6;
                }
                &:hover{
                    text-decoration: none;
                    color: #FFF;
                }
            }
            .glyphicon {
                z-index: 2;
                font-size: 10px;
                transition: all 350ms ease;
            }
            &.selected {
                .glyphicon {
                    transform: rotate(-180deg);
                }
                .content_wrapper {
                    animation: expandMenuDropdown 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                    @include media(lg) {
                        animation: expandMenuDropdownSmall 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                    }
                    &.left-side {
                        animation: expandMenuDropdownSided 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                        @include media(lg) {
                            animation: expandMenuDropdownSmall 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                        }
                    }
                    &.right-side {
                        animation: expandMenuDropdownSided 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                        @include media(lg) {
                            animation: expandMenuDropdownSmall 400ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowMenuVisible 1ms 400ms forwards;
                        }
                    }
                }
            }
            @keyframes expandMenuDropdownSmall {
                0% {
                    transform: translateY(-120%);
                }
                100% {
                    transform: translateY(16px); /* 1rem */
                }
            }
            @keyframes expandMenuDropdown {
                0% {
                    transform: translateX(-50%) translateY(-120%);
                }
                100% {
                    transform: translateX(-50%) translateY(16px); /* 1rem */
                }
            }
            @keyframes expandMenuDropdownSided {
                0% {
                    transform: translateY(-120%);
                }
                100% {
                    transform: translateY(16px); /* 1rem */
                }
            }
            @keyframes overflowMenuVisible {
                0% {
                    overflow: hidden;
                }
                100% {
                    overflow: visible;
                }
            }
        }
    }
    .little_guy {
        position: absolute;
        top: 26px; /* Ajusta la posición del triángulo */
        transition: width 150ms ease-in-out;
        transform: translate(-50%);
        border-radius: 4px;
        height: 3px;
        background-color: #F36C17;
        z-index: 2;
    }
    .content_container {
        display: flex;

        width: auto;
        max-width: 100vw; /* El tamaño inicial es 0 */
        //max-height: 560px; /* 32rem */
        height: 560px;
        

        padding: 16px 24px;
        background: none;
        opacity: 0;
        
        position: absolute;
        left: 0;
        top: 40px;
        transform: translateX(-50%);
        background: var(--image-gradient, linear-gradient(134deg, #14151C 36.15%, #5B6876 198.36%), lightgray 50% / cover no-repeat);
        border-radius: 4px; /* 0.25rem */
        z-index: 801;

        height: max-content;
        //border:3px solid orangered;
        max-height: unset;
        height: unset;
        &.visible {
            opacity: 1;
        }
        
        @include media(lg) {
            transform: unset !important;
            left: unset !important;
            right: unset !important;
            width: 90vw;
            max-width: unset;
            max-height: 950px;
        }
        &.left-side {
            transform: translateX(-20%);
        }
        &.center-side {
            @include media(lg) {
                //left: 0;
                //right: 0;
                //margin-left: auto;
                //margin-right: auto;
            }
        }
        &.right-side {
            transform: translateX(-70%);
            
            .content_wrapper {
                //max-height: 384px; /* 24rem */
            }
            @include media(lg) {
                //left: 0;
                //right: 0;
                //margin-left: auto;
                //margin-right: auto;
            }
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            column-gap: 40px; /* 2.5rem */
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;

            max-width: unset;
            width: max-content;

            height: max-content;
            max-height: 644px; /* 29rem */


            z-index: 1;
            top: 20px;
            
            //height: 544px;
            //border: 2px solid magenta;
            //border:3px solid magenta;
            cursor: auto;
            opacity: 0;
            
            min-height: unset;
            max-height: unset;
            &.fade-in {
                animation: fadeIn2 100ms ease-in-out forwards;
            }
            @keyframes fadeIn2 {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            @include media(lg) {
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                //max-width: 670px;
                max-height: unset;
                min-width: 120px;
                width: max-content;
                max-width: unset;
            }
            
            .menu_lol {
                flex: 1;
                min-width: 128px; /* 8rem */
                position: relative;
                display: flex;
                align-items: flex-start;
                padding: 0 8px; /* 0.5rem */
                padding-left: 12px; /* 0.75rem */
                flex-direction: column;
                height: max-content;
                gap: 4px;
                margin-bottom: 6.4px; /* 0.4rem */

                //border: 2px solid whitesmoke;
                height: unset !important;
                max-height: unset !important;
                flex:1;
                min-height: unset !important;
                
                a.title {
                    &:hover {
                        text-decoration: underline;
                    }
                }
                @include media(lg) {
                    min-width: 200px;
                }
                @media (min-width: 2560px) {
                    min-width: 96px; /* 6rem */
                }
                @media (min-width: 2704px) {
                    min-width: 96px; /* 6rem */
                }
                .title {
                    color: #F6F6FA;
                    font-family: Rajdhani;
                    font-size: 18px; /* 1.125rem */
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20.1px; /* 134% of 1.125rem */
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    margin-top: 12.8px; /* 0.8rem */
                    .icon {
                        font-size: 16px; /* 1rem */
                        transform: unset;
                        filter: invert(1);
                        width: 14px;
                    }
                    @media (min-width: 2560px) {
                        font-size: 12.8px; /* 0.8rem */
                    }
                    @media (min-width: 2704px) {
                        font-size: 12.8px; /* 0.8rem */
                    }
                }
                .submenu {
                    display: flex;
                    flex-direction: column;
                    gap: 1.6px; /* 0.1rem */
                    row-gap: 4.8px; /* 0.3rem */
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: max-content;
                    max-width: 400px; /* 25rem */
                    height: auto;
                    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
                    
                    //border:2px solid greenyellow;
                    margin:0;
                    .submenuItem {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: #BCBDCB;
                        font-family: Rajdhani;
                        font-size: 16px; /* 1rem */
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 1.125rem */
                        &:hover {
                            text-decoration: underline;
                        }
                        @media (min-width: 2560px) {
                            font-size: 11.2px; /* 0.7rem */
                        }
                        @media (min-width: 2704px) {
                            font-size: 11.2px; /* 0.7rem */
                        }
                    }
                }
            }
        }
    }
}
.floating_menu {
    display: none;
    flex-direction: column;
    
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000;
    z-index: 1003;
    gap: 16px; /* 1rem */
    padding: 20px;
    padding-top: 80px; /* 5rem */
    padding-bottom: 160px; /* 10rem */
    overflow: auto;
    &.visible {
        @include media(md) {
            display: flex;
        }
    }

    .close {
        position: fixed;
        top: 20px;
        right: 20px;
        font-size: 32px; /* 2rem */
        color: #FFF;
        z-index: 11;
    }
    .barrier {
        width: 100%;
        position: fixed;
        background: #000;
        height: 80px; /* 5rem */
        top: 0;
        left: 0;
        z-index: 10;
    }
    .title {
        font-size: 40px; /* 2.5rem */
        line-height: 40px; /* 2.5rem */
        display: flex;
        align-items: center;
        color: #FFF;
        position: sticky;
        top: 0;
        background: #000;
        z-index: 9;
        .glyphicon {
            font-size: 20.8px; /* 1.3rem */
            margin-left: 16px; /* 1rem */
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        &.visible {
            .glyphicon {
                transform: rotate(-180deg);
            }
        }
    }
    .list {
        display: flex;
        flex-direction: column;
        
        max-height: 0px;
        overflow: hidden;
        width: 100%;
        height: max-content;
        color: #FFF;
        gap: 8px; /* 0.5rem */
        
        .subtitle {
            padding-left: 16px; /* 1rem */
            color: #FFF;
            font-size: 40px; /* 2.5rem */
            line-height: 40px; /* 2.5rem */
            color: #F36C17;
            display: flex;
            align-items: center;
            gap: 8px; /* 0.5rem */
            .icon {
                filter: invert(1);
                width: 32px; /* 2rem */
            }
        }
        .link {
            color: #FFF;
            padding-left: 24px; /* 1.5rem */
            font-size: 32px; /* 2rem */
            line-height: 32px; /* 2rem */
        }
        &.visible {
            max-height: unset;
            overflow: unset;
        }
    }
}

