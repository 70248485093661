
.spinner{
    padding:24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:12px;
    position: absolute;
    border: 1px solid rgba(0,0,0,0.01);
    .ripple{
        position: relative;
        display: inline-block;
        width: 80px;
        height: 80px;
        div {
            position: absolute;
            border: 4px solid #FF7C11;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            &:nth-child(2) {
                animation-delay: -0.5s;
            }
            @keyframes lds-ripple {
                0% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 0;
                }
                4.9% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 0;
                }
                5% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 1;
                }
                100% {
                top: 0px;
                left: 0px;
                width: 72px;
                height: 72px;
                opacity: 0;
                }
            }
        }
    }
    .label{
        color: #FFF;
    }
}