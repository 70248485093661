@import '../../styles/mediaqueries';

.format-selection{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
    max-width: 1480px;
    color: #FFF;
    position: relative;
    .title{
        z-index: 4;
    }
    .loading{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(0px);
        color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
        opacity:0;
        transition: all 0.3s ease-in-out;
        z-index:3;
        &.visible{
            display: flex;
            backdrop-filter: blur(5px);
            opacity: 1;
        }
    }
    .list{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
        position: relative;
        
        
        padding: 12px;

        
        
        .item{
            width: calc(25% - 18px);
            min-width: 190px;
            max-width: 368px;
            cursor: pointer;
            text-align: center;
            line-height: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @include media(md){
                width: 100%;
            }
            .radio{
                border-radius:50%;
                width: 20px;
                height: 20px;
                outline: 2px solid #FFF;
                border:4px solid black;
            }
            .name{
                font-size: 24px;
            }
            .description{
                font-size: 12px;
                font-weight: 300;
                color: #999;
            }
            .instructions{
                font-size: 12px;
                font-weight: bold;
            }
            &.selected{
                .radio{
                    outline-color:#F36C17;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                }
                .name{
                    color:#f68802;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &:hover{
                .radio{
                    outline-color:#F36C17;
                }
                .name{
                    color:orange;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    .list-small{
        display: flex;
        border-top: 1px solid #3e3e3e;
        padding-top: 24px;
        gap:8px;
        font-size: 14px;
        line-height: 1.42857;
        flex-wrap: wrap;
        font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
        .item{
            display: flex;
            gap: 6px;
            align-items: center;
        }
        a.item:hover{
            cursor: pointer;
            text-decoration: underline;
            color:#f68802;
        }
    }


}




