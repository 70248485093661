

.membership{
    display: flex;
    align-items: center;
    gap:8px;
    //font-family: "Montserrat", "HelveticaNeue-Medium", "Helvetica Neue Medium", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #eee;
    &.cta_member{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .glyphicon{
        margin-bottom: 4px;
        color: rgb(245, 157, 25);
        background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .money{
        font-weight:bold;
        font-size: 1.2em;
        color: #fff;
        display: inline-block;
        background-color: green;
        padding: 5px;
        border-radius: 5px;
        margin-right: 2px;
    }
    .chip{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 10px;
        border-radius: 6px;
        
        font-size: 14px;
        font-weight: 400;
        font-style: italic;
        background-color: #b7b7b7;
        color: #525252;

        &.active{
            background-color: darkolivegreen;
            color:chartreuse;
            text-transform: uppercase;
            font-style: normal;
        }
        &.accent{
            background-color: rgb(245, 157, 25);
            background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
            color: #FFF;
            text-transform: uppercase;
            font-style: normal;
        }
        &.canceled{
            background-color: #f54b28;
            color: #fff;
            text-transform: uppercase;
            font-style: normal;
        }
    }
}





