@font-face { 
    font-family: 'crateicons';
    src: url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.eot');
    src: url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.eot#iefix') format('embedded-opentype'),
        url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.woff2') format('woff2'), 
		url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.woff') format('woff'),
		url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.ttf') format('truetype'),
        url('https://d30upb8d426d8z.cloudfront.net/fonts/crateicons6.svg?1#crateicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.glyphicon {
  position: relative;
  display: inline-block;
  font-family: 'crateicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-search:before { content: '\e804'; }
.glyphicon-card:before { content: '\e806'; }
.glyphicon-star:before { content: '\e807'; }
.glyphicon-education:before { content: '\e80a'; }
.glyphicon-region:before { content: '\e80b'; }
.glyphicon-user:before { content: '\e80c'; }
.glyphicon-film:before { content: '\e80d'; }
.glyphicon-cross:before { content: '\e80f'; }
.glyphicon-settings:before { content: '\e810'; }
.glyphicon-right:before { content: '\e811'; }
.glyphicon-left:before { content: '\e812'; }
.glyphicon-envelope:before { content: '\e813'; }
.glyphicon-unchecked:before { content: '\e817'; }
.glyphicon-thumb-up:before { content: '\e819'; }
.glyphicon-certificate:before { content: '\e81a'; }
.glyphicon-pause:before { content: '\e81d'; }
.glyphicon-play:before { content: '\e81e'; }
.glyphicon-check:before { content: '\e81f'; }
.glyphicon-list-alt:before { content: '\e820'; }
.glyphicon-info-alt:before { content: '\e84f'; }
.glyphicon-question:before { content: '\e850'; }
.glyphicon-bell:before { content: '\e852'; }
.glyphicon-down:before { content: '\e853'; }
.glyphicon-edit:before { content: '\e858'; }
.glyphicon-prize:before { content: '\e8e2'; }
.glyphicon-newspaper:before { content: '\e904'; }
.glyphicon-crate-s:before { content: '\e906'; }
.glyphicon-image:before { content: '\e90d'; }
.glyphicon-headphones:before { content: '\e910'; }
.glyphicon-music:before { content: '\e911'; }
.glyphicon-square-play:before { content: '\e912'; }
.glyphicon-file-picture:before { content: '\e927'; }
.glyphicon-file-music:before { content: '\e928'; }
.glyphicon-file-video:before { content: '\e92a'; }
.glyphicon-file-zip:before { content: '\e92b'; }
.glyphicon-folder-download:before { content: '\e933'; }
.glyphicon-price-tag:before { content: '\e935'; }
.glyphicon-tv:before { content: '\e95b'; }
.glyphicon-bubbles:before { content: '\e96c'; }
.glyphicon-hour-glass:before { content: '\e979'; }
.glyphicon-lock:before { content: '\e98f'; }
.glyphicon-trophy:before { content: '\e99e'; }
.glyphicon-fire:before { content: '\e9a9'; }
.glyphicon-star-half:before { content: '\e9d8'; }
.glyphicon-star-empty:before { content: '\e034'; }
.glyphicon-notification:before { content: '\ea08'; }
.glyphicon-plus:before { content: '\ea0a'; }
.glyphicon-minus:before { content: '\ea0b'; }
.glyphicon-info:before { content: '\ea0c'; }
.glyphicon-cloud-download:before { content: '\ea10'; }
.glyphicon-google-plus-square:before { content: '\ea8c'; }
.glyphicon-facebook:before { content: '\ea90'; }
.glyphicon-facebook-square:before { content: '\ea91'; }
.glyphicon-instagram:before { content: '\ea92'; }
.glyphicon-twitter:before { content: '\ea96'; }
.glyphicon-paypal:before { content: '\ead8'; }
.glyphicon-youtube-play:before { content: '\f16a'; }
.glyphicon-cube:before { content: '\f1b2'; }
.glyphicon-credit-card:before { content: '\f283'; }
.glyphicon-link:before {content: '\e9cb';}