

.consumptions{
    &.row-section {
        .feat-list{
            li{
                //gap: 16px;
                display: flex;
                align-items: center;
            }
        }
    }
}




