@import '../../styles/mediaqueries';


.faq{
    background-color: #0E0E0E;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    color: #BDBDBD;

    .content{
        max-width: 1340px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;

        padding-bottom: 120px;
        .title{
            color: #BDBDBD;
            font-size: 40px;
            margin: 20px 0;
            font-weight: normal;
        }

        .faq-list{
            display: flex;
            gap: 10%;
            flex-wrap: wrap;
            @include media(md){
                flex-direction: column;
            }
            .column{
                flex:1;
                display: flex;
                flex-direction: column;
                gap: 10px;
                p{
                    strong{
                        width: 100%;
                        display: inline-flex;
                        color: #BDBDBD;
                    }
                    a{
                        color: #FFF;
                        cursor: pointer;
                        &:hover{
                            color: #ffa500;
                            text-decoration: underline;
                        }
                    }
                    font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
                    -webkit-font-smoothing: subpixel-antialiased;
                    font-size: 14px;
                    line-height: 1.42857;
                    
                    color: #888888
                }
                &.wide{
                    padding: 40px 0px;
                    width: 100%;
                    flex:unset;
                }
            }

            
        }
    }
}