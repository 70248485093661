@import '../../styles/mediaqueries';

.plans{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 32px;
    padding-bottom: 100px;

    background-color: #151514;

    animation: fadeUp 350ms ease-in-out forwards;
    &.visible{
        display: flex;
    }
    
    @keyframes fadeUp {
        0%{
          opacity: 0;
          transform: translateY(40px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
    }
    .title{
        color: #ffffff;
        font-weight: 600;
        line-height: 26px;
        font-size: 24px;
        opacity: 1;
        transition: all 200ms ease-in-out;
        &.hidden{
            opacity: 0;
        }
        @include media(md){
            padding: 0 8px;
            text-align: center;
        }
    }
    .subtitle{
        line-height: 25px;
        font-weight: 600;
        color: #969696;
        font-size: 15px;

        .accent{
            color:#F36C17;
        }
        @include media(md){
            padding: 0 8px;
            text-align: center;
        }
    }
    .switch{
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .option{
            cursor: pointer;
            color: #FFF;
            line-height: 20px;
            font-size:14px;
        }
        .bar{
            border-radius: 40px;
            padding: 2px;
            width: 42px;
            height: 20px;
            background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
            display:flex;
            position: relative;
            
            
            .circle{
                background-color:#fff;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                position: absolute;
                margin:auto;
                top:0;
                bottom:0;
                transition:all 200ms ease;
            }
            &.left{
                .circle{
                    left:2px;
                }
            }

            &.right{
                .circle{
                    left:calc(100% - 20px);
                }
            }
        }
    }
    .list{
        width: calc(100% - 40px);
        max-width: 1000px;
        //height: 780px;
        min-height: 400px;
        //height: 840px;
        height: max-content;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        justify-content: center;

        @include media(lg){
            flex-wrap: wrap;
            justify-content: center;
        }
        @include media(md){
            flex-direction: column;
            align-items: center;
            height: auto;
        }
        .plan{
            position: relative;
            padding: 32px 0;
            padding-bottom: 20px;

            color: #fff;
            background-color: #202020;
            border-radius: 6px;
            max-width: 297px;
            width: 100%;

            min-height: 800px;
            //max-height: 800px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            transition: all 500ms ease-in-out;

            margin-top:30px;
            @include media(lg){
                order: 2;
            }
            @include media(md){
                max-width: 400px;
                margin-top: 0;
            }
            &.accent{
                //border:1px solid #f58802;
                min-height: 830px;
                max-width: 314px;
                padding-bottom: 54px;

                margin-top:0;
                @include media(lg){
                    min-height: unset;
                    padding-bottom: 20px;
                    max-width: 297px;
                    order: 1;
                }
                @include media(md){
                    max-width: 400px;
                }


                --borderWidth: 3px;
                background: #1D1F20;
                position: relative;
                border-radius: var(--borderWidth);
                &::after{
                    content: '';
                    position: absolute;
                    top: calc(-1 * var(--borderWidth));
                    left: calc(-1 * var(--borderWidth));
                    height: calc(100% + var(--borderWidth) * 2);
                    width: calc(100% + var(--borderWidth) * 2);
                    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #cb1616, #f8e40f, #cf9709);
                    border-radius: calc(2 * var(--borderWidth));
                    z-index: -1;
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                    
                }



            }
            &.has_free{
                @include media(lg){
                    order: 3;
                }
            }
            .pretitle{
                color: #717171;
                font-size: smaller;
                text-align: center;
            }
            .pic{
                width: 120px;
                height: 120px;
            }
            .name{
                color: #fff;
                font-size: large;
                margin-top: 8px;
                text-align: center;
                font-size: 22px;
            }
            .old-price{
                font-size: 22px;
                color: #969696;
                text-decoration: line-through;
            }
            .price{
                color: #F36C17;

                font-size: 32px;
                font-weight: 300;
                display: inline-flex;
                line-height: 40px;
                .accent{
                    font-size: 40px;
                    display: inline-flex;

                    .ticker-digit{
                        text-align: center;
                    }
                }
            }

            .note{
                color: #969696;
                font-size: smaller;
                text-align: center;
                transition: opacity 1s ease-in-out;
            }
            .cta{
                color: white;
                border: 0;
                font-size: 22px;
                font-weight: normal;
                cursor: pointer;
                transition: all 0.2s ease;
                display: block;
                display: block;
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;

                background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                border-radius: 30px;
                padding: 8px;
                cursor: pointer;
                @keyframes animatedgradient {
                    0%{background-position:0% 50%}
                    50%{background-position:100% 50%}
                    100%{background-position:0% 50%}
                }

                &:hover{
                    transform: scale(1.03);
                    //box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                    //-webkit-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                    //-moz-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);

                    background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #ec2c01,#E23B17, #F36C17, #f7b722, #f7f022 );
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                }

                &.selected{
                    animation: none;
                    cursor: auto;
                    color:#0f0f20;
background: linear-gradient(to right, #868f96 0%, #596164 100%);

                     &:hover{
                        transform: none;
                     }
                }
            }
            
            .features{
                border-top: 2px dotted #888;
                width: 100%;
                padding: 20px;
                padding-bottom: 0;
                .item{
                    display: flex;
                    position: relative;
                    align-items: center;
                    gap: 8px;
                    color: rgb(238, 238, 238);
                    font-size: 14px;
                    line-height: 35px;
                    position: relative;
                    .tooltip{
                        width: 100%;
                        position: absolute;
                        bottom: 100%;
                        left: 40%;
                        background-color: rgba(8.2, 8.2, 7.8, 0.8);
                        line-height: 1.5em;
                        padding: 15px;
                        text-align: left;
                        border-radius: 20px;
                        font-size: 14px;
                        opacity: 0;
                        transition: opacity 0.2s ease;
                        pointer-events: none;
                        z-index: 14;
                        &:before{
                            content: "\25B2";
                            width: 20px;
                            height: 10px;
                            transform: rotate(180deg);
                            display: inline-block;
                            color: rgba(8.2, 8.2, 7.8, 0.8);
                            position: absolute;
                            bottom: -15px;
                            left: 8%;
                            font-size: 18px;
                            margin-left: -10px;
                            text-align: center;
                        }
                    }
                    &:hover{
                        .tooltip{
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                    a{
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .donate{
                display: flex;
                justify-content: center;
                width:100%;
                height: 35px;
                cursor: pointer;
                align-items: center;
                font-size: 11px;
                color: #cdcdcd;
                gap: 5px;

                position:absolute;
                bottom: 10px;
                left:0; right:0;
                margin: auto;
                &:hover{
                    filter: brightness(0.8);
                }
                  
            }

        }
        
    }
    
    .custom-claim{
        display: inline-flex;
        color: #EEE;
        font-size: 14px;
        gap:4px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 0 12px;
        a{
            text-decoration: underline;
            color:#F36C17;
        }
    }
    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: rgba(0,0,0,0);
        /* Not removed via appearance */
        margin: 0;
        
        font: inherit;
        color: rgba(0,0,0,0);
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid #cdcdcd;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
        
        display: grid;
        place-content: center;
        cursor:pointer;
    }
    
    input[type="checkbox"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #f68802;
        /* Windows High Contrast Mode */
        background-color: #f68802;
    }
    
    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
    
    input[type="checkbox"]:focus {
        outline: max(2px, 0.15em) solid rgba(0,0,0,0);
        outline-offset: max(2px, 0.15em);
    }
}