@import '../../styles/mediaqueries';


.enterprise{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 6.25rem;
    background-color: #151514;
    font-family: Rajdhani;

    .title {
        color: #ffffff;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem; /* 82.519px */
        letter-spacing: 0.0352rem;
        opacity: 1;
        transition: all 200ms ease-in-out;

        &.hidden {
            opacity: 0;
        }

        @include media(md2) {
            padding: 0 0.5rem;
            text-align: center;
            font-size: 3rem;
        }
    }

    .subtitle {
        line-height: 1.5625rem;
        font-weight: 600;
        color: #969696;
        font-size: 0.9375rem;

        .accent {
            color: #F36C17;
        }

        @include media(md2) {
            padding: 0 0.5rem;
            text-align: center;
        }
    }

    .switch {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        cursor: pointer;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;

        .option {
            cursor: pointer;
            background: var(--Linear, linear-gradient(157deg, #F4F4F4 22.38%, #6F8090 193.12%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.14375rem;
        }

        .bar {
            border-radius: 24px; /* 40px * 0.6 */
            padding: 0.075rem; /* 0.125rem * 0.6 */
            width: 3.1875rem; /* 5.3125rem * 0.6 */
            height: 1.6875rem; /* 2.8125rem * 0.6 */
            background: #FFF;
            display: flex;
            position: relative;
        
            @media (min-width: md) {
                width: 2.25rem; /* 3.75rem * 0.6 */
                height: 1.125rem; /* 1.875rem * 0.6 */
            }
        
            .circle {
                background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                border-radius: 50%;
                width: 1.3125rem; /* 2.1875rem * 0.6 */
                height: 1.3125rem; /* 2.1875rem * 0.6 */
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                transition: all 200ms ease;
        
                @media (min-width: md) {
                    width: 0.9375rem; /* 1.5625rem * 0.6 */
                    height: 0.9375rem; /* 1.5625rem * 0.6 */
                }
            }
        
            &.left {
                .circle {
                    left: 0.1875rem; /* 0.3125rem * 0.6 */
                }
            }
        
            &.right {
                .circle {
                    left: calc(100% - 1.5rem); /* calc(100% - 2.5rem) * 0.6 */
        
                    @media (min-width: md) {
                        left: calc(100% - 1.125rem); /* calc(100% - 1.875rem) * 0.6 */
                    }
                }
            }
        }
    }

    .list {
        width: calc(100vw - 3rem); //calc(100% - 1.25rem);
        padding-top: 1rem;
        display: flex;
        align-items: stretch;
        gap: 1.5rem;
        justify-content: center;
        opacity: 0;

        overflow-y: visible;
        overflow-x: hidden;
        //flex-wrap: wrap;
        transform: translateY(2.5rem);
        transition: all 300ms ease;
        min-height: 25rem;

        @include media(md){
            width: 100%;
            transition: none;
            margin-bottom: 2rem;
        }
        &.visible {
            opacity: 1;
            transform: translateY(0px);
        }

        &.summarized {
            @include media(md2) {
                transform: unset;
                //width: calc(100% - 2.5rem);
            }
        }

        @include media(lg) {
            flex-wrap: wrap;
            justify-content: center;
        }

        @include media(md2) {
            flex-direction: column;
            align-items: center;
            height: auto;
            transform: translateY(2.5rem) !important;
        }

        .plan {
            position: relative;
            color: #14151C;
            background-color: #F4F4F4;
            border-radius: 16px;
            max-width: 19rem;
            width: 18rem;
            //width: max-content;
            padding: 1.75rem 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            transition: all 300ms ease;
            opacity: 1;

            @include media(md2) {
                max-width: 25rem;
                min-width: unset;
                width: 100%;
                margin-top: 0;
                margin-right: 0 !important;
                transform: none !important;
                //padding-bottom: 3.75rem;
                padding-bottom: 1rem;
                transition: none;
                border-radius: 0;
                padding-left: 2rem;
                padding-right: 2rem;
            }
            &.with_toggle{
                @include media(md2) {
                    padding-bottom: 3.75rem;
                }
            }
            &.hidden {
                @include media(md2) {
                    opacity: 0;
                    padding: 0;
                    overflow: hidden;
                    max-height: 0;
                }
            }

            &.accent {
                background: var(--Gradient-1, linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%));
                position: relative;

                &.hidden {
                    @include media(md2) {
                        display: none;
                    }
                }
            }

            &.super_accent {
                color: #F4F4F4;

                .old-price {
                    @include media(md2) {
                        display: none;
                    }
                }

                .price {
                    color: #F4F4F4;
                }

                @include media(md2) {
                    display: flex;
                }
            }

            .bg_whiter {
                position: absolute;
                width: calc(100% - 0.75rem);
                height: calc(100% - 0.75rem);
                top: 0.375rem;
                left: 0.375rem;
                border-radius: 16px;
                z-index: 0;
                background: #F4F4F4;
                @include media(md){
                    border-radius: 0;
                }
            }

            .name {
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.14375rem;
                z-index: 1;

                @include media(md2) {
                    font-size: 2rem;
                }
            }

            .old-price {
                font-size: 1.375rem;
                text-decoration: line-through;
                z-index: 1;
            }

            .price {
                color: var(--Primary-400, #272830);
                font-size: 2.3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.9rem;
                letter-spacing: 0.0197rem;
                z-index: 1;

                @include media(md) {
                    font-size: 2.25rem;
                    line-height: 2.625rem;
                }

                .accent {
                    font-size: 3.8rem;
                    letter-spacing: 0.0352rem;
                    display: inline-flex;

                    @include media(md) {
                        font-size: 2.625rem;
                    }

                    .ticker-digit {
                        text-align: center;
                    }
                }
            }

            .note {
                z-index: 1;
                font-size: 1.175rem;
                font-weight: 500;
                line-height: 2.14375rem;
                text-align: center;
                transition: opacity 1s ease-in-out;

                @include media(md) {
                    font-size: 1.125rem;
                }
            }

            .features {
                width: 100%;
                min-width: max-content;
                padding: 0;
                padding-bottom: 0;
                align-items: center;
                flex-direction: column;
                display: flex;
                gap: 0rem;

                @include media(md2) {
                    gap: 0.5rem;

                    transition: all 300ms ease;
                    overflow: hidden;
                }
                &.collapsed{
                    @include media(md2) {
                        max-height: 0;
                    }
                }
                &.visible{
                    @include media(md2) {
                        max-height: 300px;
                    }
                }
                .item {
                    display: flex;
                    position: relative;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 1.1875rem;
                    font-weight: 500;
                    line-height: 2.14375rem;
                    position: relative;
                    width: 100%;
                    padding-left: 10%;
                    text-align: left;
                    justify-content: left;
                    cursor: default;

                    @include media(md2) {
                        //text-align: left;
                        //justify-content: left;
                    }
                    
                    .tooltip {
                        width: 100%;
                        position: absolute;
                        bottom: 135%;
                        left: 40%;
                        background-color: rgba(8.2, 8.2, 7.8, 0.8);
                        color: #F4F4F4;
                        line-height: 1.5em;
                        padding: 0.9375rem;
                        text-align: left;
                        border-radius: 6px;
                        font-size: 0.875rem;
                        opacity: 0;
                        transition: opacity 0.2s ease;
                        pointer-events: none;
                        z-index: 14;

                        &:before {
                            content: "\25B2";
                            width: 20px;
                            height: 10px;
                            transform: rotate(180deg);
                            display: inline-block;
                            color: rgba(8.2, 8.2, 7.8, 0.8);
                            position: absolute;
                            bottom: -16px;
                            left: 8%;
                            font-size: 1.125rem;
                            margin-left: -10px;
                            text-align: center;

                            @include media(md2) {
                                left: 50%;
                                margin-left: -0.625rem;
                                bottom: -1.25rem;
                            }
                        }

                        @include media(md2) {
                            left: 0;
                            font-size: 1.25rem;
                            transform: translateY(-6.25rem);
                            bottom: 9.375rem;
                        }
                    }
                    &:hover {
                        .tooltip {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                    a {
                        &:hover {
                            text-decoration: underline;
                        }

                        @include media(md2) {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .show-features{
                display: none;
                align-items: center;
                gap: 0.5rem;
                z-index:2;
                @include media(md){
                    display: flex;
                    cursor: pointer;;
                    //color: black;
                    width: 100%;
                    padding:0.5rem;
                    padding-top:1.5rem;
                    padding-left: 10%;
                    text-align: left;
                    font-size: 1.4rem;
                    &:hover{
                        text-decoration: none;
                    }
                }
                .icon{
                    transform: rotate(90deg);
                    margin-top:-2px;
                    transition: all 0.3s ease;
                }
                &.collapsed{
                    .icon{
                        transform: rotate(-90deg);
                    }
                }
                &.visible{
                    .icon{
                        transform: rotate(90deg);
                    }
                }
            }
            .donate {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 2.1875rem;
                cursor: pointer;
                align-items: center;
                gap: 0.3125rem;
                color: var(--Primary, #14151C);
                text-align: center;
                font-size: 0.9rem;
                font-weight: 500;
                line-height: 1.14375rem;
                position: absolute;
                bottom: 0.625rem;
                left: 0;
                right: 0;
                margin: auto;
                &:hover {
                    //filter: brightness(0.8);
                }
            }

            .subNote {
                width: max-content;
                position: absolute;
                transform: translateY(-50%);
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0.05rem 0.375rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.1875rem;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                //color: #F4F4F4;
                //background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                color: #F36C17;
                background: #F4F4F4;
                border-radius: 6px;
            }
        }
    }

    .explanation{
        padding: 0 2rem;  
        max-width: 900px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        color: #FFF;
        font-size: 1.5rem;
        font-weight: 500;
        list-style-type:disc;
    }
}