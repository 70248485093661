@import '../../styles/mediaqueries';


.member{
    display: flex;
    align-items:center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    padding: 64px max(5%, 46px);
    color: #FFF;
    gap:32px;
    background-color: #212121;
    width: calc(100% - 75px);
    margin: 37.5px auto;
    max-width: 1760px;

    @include media(md){
        padding: 32px 20px;
        width: 100%;
    }
    .tab-list{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        min-height: 45px;
        border-bottom: 1px solid gray;
        flex-wrap: wrap;
        .tab{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid gray;
            border-bottom: none;
            cursor: pointer;
            padding:4px 24px;
            height: 35px;
            transition:all 200ms ease-in-out;
            &:hover{
                text-decoration: underline;
                height:40px;
            }
            &.active{
                background-color: gray;
                color: white;
                cursor: default;
                &:hover{
                    text-decoration: none;
                    height: 35px;
                }
            }
        }
    }
    .general-loading{
        height: 100%;
        top:0;
        width: 100%;
        z-index:2;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px);
        pointer-events: none;
        
        .spinner{
            position:fixed;
            background:rgba(0,0,0,0.01);
            //outline:1px solid red;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
        }
    }
    .row-section{
        width: 100%;
        padding-bottom: 48px;
        border-bottom: 1px solid #3e3e3e;

        font-weight: 400;
        font-size: 14px;
        color: #eee;
        line-height: 20px;
        
        display: flex;
        flex-direction: column;
        gap:16px;
        .title{
            display: flex;
            align-items: center;
            gap:8px;
            //font-family: "Montserrat", "HelveticaNeue-Medium", "Helvetica Neue Medium", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
            font-family: 'Rajdhani', sans-serif;
            font-weight: 700;
            font-size: 18px;
            .glyphicon{
                margin-bottom: 4px;
            }
            .money{
                font-weight:bold;
                font-size: 1.2em;
                color: #fff;
                display: inline-block;
                background-color: green;
                padding: 5px;
                border-radius: 5px;
                margin-right: 2px;
            }
            .chip{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px 10px;
                border-radius: 6px;
                
                font-size: 14px;
                font-weight: 400;
                font-style: italic;
                background-color: #b7b7b7;
                color: #525252;

                &.active{
                    background-color: darkolivegreen;
                    color:chartreuse;
                    text-transform: uppercase;
                    font-style: normal;
                }
                &.canceled{
                    background-color: #ff6344;
                    color: #fff;
                    text-transform: uppercase;
                    font-style: normal;
                }
            }
        }
        .subtitle{
            font-weight: 400;
            font-size: 18px;
            color: #eee;
            line-height: 20px;
        }

        .claim{
            font-weight: 400;
            font-size: 14px;
            color: #eee;
            line-height: 20px;

            a{
                cursor: pointer;text-decoration: underline;
                color: #FFF;
                font-weight: 500;
                &:hover{
                    color: #f68802;
                    
                }
            }
        }
        .feat-list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap:2px;

            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #eee;
            li{
                gap: 6px;
                &::before {
                    content: "\2022";
                    color: #ff8200;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    max-width: 775px;
                }
                a{
                    &:hover{
                        color: #f68802;
                        text-decoration: underline;
                    }
                }
            }
        }
        .btn-row{
            display: flex;
            gap: 16px;
            width:100%;
            flex-wrap: wrap;
        }
        
        &.team{

            .member-list{

                .item{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

        }

        &.subscription-block{
            gap: 0;
            padding-bottom: 24px;
            .title{
                cursor: pointer;
                .menu-expand-icon{
                    transform: rotate(-90deg);
                    transition: all 300ms ease-in-out;
                }
            }
            .hidden-content{
                max-height: 0;
                overflow:hidden;
                transition: all 300ms ease-in-out;
            }
            &.active{
                .title .menu-expand-icon{
                    transform: rotate(90deg);
                }
                .hidden-content{
                    max-height: 70px;
                }
            }
        }
    }
    .user{
        display: flex;
        align-items:center;
        justify-content: flex-start;
        flex-direction: column;

        //border-bottom: 1px solid #3e3e3e;
        max-width: 1480px;
        width: 100%;
        .pic{
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f68802;
            font-size: 30px;
            overflow: hidden;
        }
        .warning{
            background-color: azure;
            color: red;
            padding: 24px;
            text-align: center;
            border-color:red;
            border-radius: 12px;
            margin: 14px auto;
            font-size: 14px;
        }
        .subtitle{
            font-size: 14px;
        }
    }
    .user-level{
        align-self: flex-start;
        
        
        .star{
            background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
        
        .upgrade{
            display: flex;
            column-gap:12px;
            flex-wrap: wrap;
            align-items: center;
            
            .accent{
                width: 100%;
                font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
                font-size: 14px;
                line-height: 1.42857;
                background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .note{
                font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
                font-size: 12px;
                line-height: 1.42857;
                color: #eee;
            }
        }
    }
    .invoicing{
        .invoicing-data-form{
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            align-items: center;
        }
    }
    .change-password{
        .input-multiple span{
            min-width: 156px;
            justify-content: flex-end;
        }
        input{
            max-width: 300px;
        }
        .submit-form{
            max-width: 462px;
        }
    }
    .additional-data-form{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        gap:6px;

        .submit-form{
            display: flex;
            align-items: center;
            justify-content: center;
            width:100%;
            color: #fff;
            background-color: #212121;
            border:1px solid #858585;
            border-color: #858585;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
            cursor: pointer;
            &:hover{
                color: #fff;
                background-color: #1c1c1c;
                border-color: #808080;
            }
        }
        .error{
            color:#ff4444;
        }
        .success{
            color:#81de2a;
        }
        .normal{
            color:#b7b7b7
        }
    }
    .danger-zone{
        .title{
            color: #ff4444;
            .glyphicon{
                margin-bottom: 0;
            }
        }
    }
    button{
        padding: 8px;
        min-width: 200px;
        border: 1px solid #FFF;
        border-radius: 30px;
        color: #FFF;
    }
    .btn{
        border: 1px solid gray;
        border-radius: 4px;
        font-size: 14px;
        padding: 4px 12px;
        width: max-content;
        cursor: pointer;
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        &:hover{
            border-color: transparent;// orange;
            //color: orange;
            background: linear-gradient(60deg, #F36C17, #f37055,#E23B17, #F36C17,#f68802, #f7b722);
            animation: animatedgradient 3s ease alternate infinite;
            background-size: 300% 300%;
            color: #FFF;
        }

        @keyframes animatedgradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }


        .glyphicon{
        }

        &.btn-danger{
            border-color:#ff4444;
            
            &:hover{
                text-decoration: underline;

                color: #FFF;
                background: linear-gradient(60deg,#ff2a00, #E23B17, #ec2c01,#E23B17 );
            }
        }
        &.btn-secondary{
            border: none;
            font-size: 12px;
            font-weight: 400;
        }
    }
}