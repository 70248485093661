@import '../../styles/mediaqueries';

.email-verify{

    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #202020;

    padding-bottom: 50px;
    padding-top: 32px;
    
    @include media(md){
        justify-content: flex-start;
        min-height: unset;
        height: max-content;
        padding: 0;
        padding-top: 24px;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: calc(352px + 200px);
        width: calc(100% - 20px);
        padding: 50px 20px;
        @include media(md){
            padding: 0;
        }
        .step{
            font-size: 14px;
            color: #d2d2d2;
            line-height: 16px;
        }
        .title{
            font-size: 32px;
            color: #fff;
            line-height: 40px;
        }
        .input-name{
            font-size: 12px;
            font-weight: 400;
            width: 100%;
            text-align: left;
            color: #FFF;
        }
        .explanation{
            color: #FFF;
            font-size:12px;
            &.big{
                font-size: 16px;
            }
            a{
                text-decoration: underline;

                cursor: pointer;
            }
        }
        .error-msg{
            color: red;
            font-size:12px;
        }
        .success-msg{
            color: green;
            font-size:12px;
        }
        .input-text{
            width: 100%;
            border-radius: 12px;
            height: 45px;
            font-size: 16px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857;
            color: #555;
            background-color: #fff;
            border: 1px solid #858585;
        }
        .forgot-password{
            color:#555;
            font-size:12px;
            cursor: pointer;

            &:hover{
                color: #FFF;
                text-decoration: underline;
            }
        }
        .newsletter{
            display: flex;
            align-items:center;
            gap:4px;
            //color: #f68802;
            //Color style changed
            background: linear-gradient(123deg, rgb(243, 108, 23) 25.9%, rgb(226, 59, 23) 89.26%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 12px;
            line-height: 24px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        .cta{
            color: white;
            border: 0;
            font-size: 22px;
            font-weight: normal;
            cursor: pointer;
            transition: all 0.2s ease;
            display: block;
            display: block;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: linear-gradient(123deg, rgb(243, 108, 23) 25.9%, rgb(226, 59, 23) 89.26%);
            border-radius: 30px;
            padding: 8px;
            cursor: pointer;
            &:hover{
                transform: scale(1.03);
                box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                -webkit-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
                -moz-box-shadow: 1px 1px 32px 3px rgba(255,122,0,0.49);
            }
        }
        .separator{
            font-size: 24px;
            text-transform: uppercase;
            color: #FFF;
        }
        .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: 30px;
            width: 100%;
            position:relative;
            cursor: pointer;
            transition: all 0.2s ease;
            height: 54px;
            .icon{
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 12px;
                border-radius: 50%;
            }
            &.cta-facebook{
                color: white;
                background-color: #1977f3;
                .icon{
                    width: 32px;
                    height: 32px;
                }
            }
            &.cta-google{
                color: #2e2e2e;
                background-color: white;
            }
            &:hover{
                text-decoration: underline;
            }
        }
        .links{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #a1a1a1;
            gap:4px;
            font-size: 15px;
            .link{
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }


}










