


.banner_web{
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 8px;
    padding-right: 24px;
    background: linear-gradient(123deg, #f36c17 25.9%, #e23b17 89.26%);
    position: relative;
    font-size:20px;
    color:#FFF;
    font-weight:400;
    //min-height: 60px;
    .banner_pic{
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left:0;
        top:0;
    }
    .content{
        text-decoration:none;
        font-size:18px;
        color:#FFF;
        font-weight:400;
        display: inline;
        div{
            cursor: pointer;
            text-decoration: none;
            font-size:18px;
            color:#FFF;
            font-weight:700;
            &:hover{
                text-decoration:underline;
            }
        }
    }
    
    .close{
        position: absolute;
        right: 18px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 24px;
        color: #FFF;
        cursor: pointer;
        font-weight: 500;
        transform: scaleX(1.5);
        height: max-content;
    }
}